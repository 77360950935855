@import '../../styles/variables';
@import '../../styles/mixin';

.video__container {
  display: flex;
  justify-content: center;

  div {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;

    .video__disabled_interaction:hover {
      cursor: auto;
    }

    video,
    iframe {
      display: block;
      width: 100%;
      height: 100%;
      background-color: black;
      cursor: pointer;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      display: flex;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      transition: opacity 0.3s;

      .icon {
        position: relative;
        top: auto;
        max-width: fit-content;
        max-height: fit-content;
        min-width: fit-content;
        min-height: fit-content;
        width: 60px; /* Decreased size */
        height: 60px;
        transition: width 0.3s, height 0.3s;

        &:hover {
          width: 80px; /* Adjusted hover size */
          height: 80px;
        }
      }
    }

    .pause__state {
      opacity: 0.3;
    }

    .play__state {
      opacity: 0;

      &:hover {
        opacity: 0.3;
      }
    }

    .has__controls {
      display: none;
    }
  }
}
