@import '../../../styles/variables';
@import '../../../styles/mixin';

.feature__title {
  order: 4;
  font-size: 20px;
  font-weight: 700;
  margin-top: 32px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 16px;
}

.items_section {
  order: 5;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 24px;
  width: 100%;

  @include media(lg) {
    gap: 32px;
  }

  .item {
    display: flex;
    gap: 12px;

    .image {
      & > span {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: left;
    }
  }
}
