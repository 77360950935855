@import '../../../styles/variables';
@import '../../../styles/mixin';

.items_section {
  order: 4;
  display: flex;
  gap: 10px;
  margin-top: 24px;
  padding-top: 24px;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid $gray-100;

  .item {
    display: flex;
    width: auto;
    height: 24px;
    width: 24px;
    flex-direction: column;
    align-items: center;

    .image {
      position: relative;
      height: 24px !important;
      width: 24px !important;

      & > img {
        height: 24px !important;
        width: 24px !important;
      }
    }
  }
}
