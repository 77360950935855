@import '../../styles/variables';
@import '../../styles/mixin';

.crmmodal {
  font-size: $text-base;
  position: fixed;
  
  div[class~='modal__content'] {
    background-color: $crm-background-color;
    box-shadow: $crm-modal-shadow;
  }

  div[class='modal__body'] {
    padding: 0;
    margin-top: 20px;

    @media screen and (min-width: 550px) {
      margin-top: 30px;
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 20;

    @media screen and (min-width: 550px) {
      right: 15px;
      top: 15px;
    }
  }

  .container {
    background: inherit;
    position: relative;
    justify-content: space-between;
    text-align: center;
    padding: 0 0 15px;

    @media screen and (min-width: 550px) {
      padding: 0 20px 30px;
      justify-content: center;
    }

    .header {
      background: inherit;
      position: sticky;
      translate: 0 -2px -2px;
      padding: 0 10px;
      top: 0;
      z-index: 1;

      p {
        padding: 10px 0 25px;

        @media screen and (min-width: 550px) {
          padding-bottom: 40px;
        }
      }
    }
  }

  .title {
    text-transform: uppercase;
    color: $crm-header-color;
  }

  form {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    div {
      p {
        padding-top: 4px !important;
        padding-bottom: 0px !important;
      }
    }

    @media screen and (min-width: 550px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 40px;
    }
  }

  .submit {
    grid-column: 1 / 3;
    height: 53px;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .option {
    height: fit-content;

    .input_input__control__5IFUO {
      position: relative;
      top: 2px;
    }
  }

  strong {
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 4px 1px;
    background-image: linear-gradient(
      to right,
      rgb(135, 135, 135) 50%,
      transparent 50%
    );

    &:hover {
      color: $label-btn-link;
      background-image: linear-gradient(
        to right,
        $label-btn-link 50%,
        transparent 50%
      );
    }
  }

  .continue_link {
    text-decoration-line: underline;
    font-size: 16px;
    margin-top: 30px;
    box-sizing: border-box;
    white-space: pre-wrap;
  }
}
