// *****IMPORTANT NOTE *****
// This file is the default theme that controls all the styling of the website; Always keep it updated.
// ******************************

$breakpoints: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
  3xl: 2048px,
);

// Primary colors
$primary: #6baa33;
$primary-100: #e9ecf0;
$primary-200: #d8dce3;
$primary-300: #bbc1c9;
$primary-400: #858f9d;

// Secondary colors
$secondary: #6da738;
$secondary-100: #eef9e0;
$secondary-200: #e5f3d4;
$secondary-300: #cde7af;
$secondary-400: #8dc457;

// Grayscale
$white: #ffffff;
$gray-25: #e9ecf0;
$gray-50: #f2f4f7;
$gray-100: #d4d6d8;
$gray-200: #d8dce3;
$gray-300: #bbc1c9;
$gray-400: #858f9d;
$gray-500: #576170;
$gray-600: #37404e;
$gray-700: #1b2129;
$gray-dark: #0c1014;
$black: #030405;

// Background
$background-theme: #ffffff;
$background-theme-dark: #ffffff;
$background-page: #ffffff;

// Text color base
$text-color-default: #2a384e;

// Color text default and Heading (dark-theme)
$heading-color: $gray-600;
$body-color-text: #2a384e;

//Placeholder
$placeholder-bg: $gray-50;
$placeholder-bg-100: #ececec;
$placeholder-bg-200: #ebf1f1;
$placeholder-bg-300: #e3eff1;

$placeholder-bg-dark: rgba(43, 44, 44, 0.3);
$placeholder-bg-dark-100: rgba(76, 75, 75, 0.3);
$placeholder-bg-dark-200: rgba(33, 41, 41, 0.4);
$placeholder-bg-dark-300: rgba(43, 59, 61, 0.3);

// Border
$border-radius: 12px;
$border-radius-md: 8px;
$border-radius-sm: 2px;
$border-radius-full: 9999px;
$border-radius-pill: 16px;

// Spacing
$space-x: 16px;
$space-y: 11px;

// Transition
$transition-all: all 0.25s ease-in;

// Opacity
$opacity: 0.8;

// Overlay Color
$overlay-bg: rgba(18, 37, 33, 0.3);

// Drawer or Portal 
$drawer-background: none;
$drawer-icon-touch: #cbd5e1;
$drawer-shadow: 0px 4px 24px 0px rgba(7, 13, 21, 0.0509803922);
$drawer-background-more-info: none;
$drawer-color-icon-more-info: none;

// Shadow
$box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 8px;
$box-shadow-2: 0px 0px 12px rgba(186, 186, 186, 0.3);
$box-shadow-3: 0px -6px 8px -6px rgb(186 186 186 / 30%);

// Font Size
$text-small: 0.55rem;
$text-xs: 0.75rem;
$text-sm: 0.875rem;
$text-base: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-2xl: 1.5rem;
$text-3xl: 1.875rem;
$text-4xl: 2.25rem;
$text-5xl: 3rem;
$text-6xl: 3.75rem;
$text-7xl: 4.5rem;
$text-8xl: 6rem;
$text-9xl: 8rem;

// Base Component

// Button
$border-radius-btn: 16px;
$border-width-btn: 1px;
//primary
$btn-primary: #6baa33;
$label-btn-primary: $white;
$border-btn-primary: #6baa33;
$btn-primary-hover: #6baa33;
$label-btn-primary-hover: $white;
$border-btn-primary-hover: #6baa33;
$btn-primary-pressed: #6baa33;
$label-btn-primary-pressed: $white;
$border-btn-primary-pressed: #6baa33;
$btn-primary-disabled: #bbc1c9;
$label-btn-primary-disabled: #ffffff;
$border-btn-primary-disabled: #bbc1c9;

//secondary
$btn-secondary: #fafbfc;
$label-btn-secondary: #030405;
$background-btn-secondary: #fafbfc;
$btn-secondary-hover: #f2f4f7;
$label-btn-secondary-hover: #1b2129;
$background-btn-secondary-hover: #f2f4f7;
$btn-secondary-pressed: #e9ecf0;
$label-btn-secondary-pressed: #0c1014;
$background-btn-secondary-pressed: #e9ecf0;
$btn-secondary-disabled: #e9ecf0;
$label-btn-secondary-disabled: #bbc1c9;
$background-btn-secondary-disabled: #f7f8fa;

//tertiary
$btn-tertiary: none;
$label-btn-tertiary: none;
$btn-tertiary-hover: none;
$label-btn-tertiary-hover: none;
$btn-tertiary-pressed: none;
$label-btn-tertiary-pressed: none;
$btn-tertiary-disabled: none;
$label-btn-tertiary-disabled: none;


//link
$label-btn-link: #6baa33;
$label-btn-link-hove: #6baa33;

// Swiper Pagination Bullet
$swiper-bullet: $gray-400;
$swiper-bullet-active: $primary;

//Close
$btn-close-font-size: 20px;
$btn-close-padding: 4px;
$btn-close-border-radius: 0;
$btn-close-icon-color: #576170;
$btn-close-background: inherit;
$btn-close-icon-color-hover: #0c1014;
$btn-close-background-hover: inherit;
$btn-close-border-color: transparent;
$btn-close-border-color-hover: transparent;

// Input
$border-radius-input: 16px;
$input-bg: #fafbfc;
$input-disabled-bg: #f7f8fa;
$input-color: #030405;
$input-border-color: #e9ecf0;
$input-border-color-hover: #d8dce3;
$input-border-color-activate: #6baa33;
$input-color-label: #576170;
$input-color-label-activate: #6baa33;
$input-ckek-border-color: #bbc1c9;
$input-ckek-background-color: #fafbfc;
$input-ckek-background-select: #6baa33;
$input-color-label-ckeck: $gray-600;
$input-check-color: #6baa33;
$input-check-select: $white;
$input-invalid-color: #cc321b;
$input-icon-color: 1b2129;
$input-icon-color-activate: 6da738;
$input-check-radio-button: 6da738;

// Badge
$border-radius-badge: 12px;

//Badge Default
$badge: #6baa33;
$badge-border-color: #6baa33;
$badge-text: #ffffff;

//discount
$badge-discount: #6baa33;
$badge-discount-border-color: #6baa33;
$badge-discount-text: #ffffff;

//seller
$badge-seller: #6baa33;
$badge-seller-border-color: #6baa33;
$badge-seller-text: #ffffff;

//specials
$badge-specials: #030405;
$badge-specials-border-color: #030405;
$badge-specials-text: #ffffff;

//warning
$badge-warning: #ffbb11;
$badge-warning-border-color: #ffbb11;
$badge-text-warning: #664b07;

//Badge BOGO
$bagde-bogo: #ffffff;
$badge-bogo-text: #be6d00;
$badge-bogo-border: #f8cf99;

//counter
$badge-counter: #de2d2c;
$badge-counter-text: #ffffff;

// Text Colors
$errors: #cc321b;
$success: #97cc69;
$hover: #6baa33;
$alert-message: #e63541;

//Select
$select-menu-bg: $white;
$select-menu-border-color: $select-menu-bg;
$select-menu-toogle-border: transparent;
$select-menu-toogle-border-hover: transparent;
$select-menu-border-radius: 4px;
$selct-thumb-color: $gray-200;
$select-menu-item-bg-hover: $gray-50;
$select-menu-item-bg-select: $gray-25;
$select-menu-item-color: $black;
$select-menu-item-color-hover: $black;
$select-menu-item-color-select: $black;
$select-menu-item-shadow: 0px 0px 12px rgb(186 186 186 / 30%);

// Alert
$login-success-border-color: #030405;
$login-success-color: #030405;
$login-text-success-color: #ffffff;

$alert-errors-border-color: #f8d3cf;
$alert-errors-color: #f8d3cf;
$alert-text-errors-color: #85140a;
$alert-errors-icons-color: #85140a;

$alert-success-border-color: #a7e4bb;
$alert-success-color: #a7e4bb;
$alert-text-success-color: #0e4b22;
$alert-success-icons-color: #0e4b22;

$alert-warning-border-color: #fff1cf;
$alert-warning-color: #fff1cf;
$alert-text-warning-color: #99700a;
$alert-warning-icons-color: #99700a;

$alert-info-color: #d3e0f5;
$alert-info-border-color: #d3e0f5;
$alert-text-info-color: #0e2952;
$alert-info-icons-color: #0e2952;

$alert-border-radius: 8px;

//Express Shipping Banner
$express-banner-bg-color: #85bb5c;
$express-banner-text-color: #0d0d0d;
$express-banner-bg-color-express-mode: #c2ddad;
$express-banner-text-color-express-mode: #294414;
$express-banner-transition-duration: 0.5s;
$express-banner-border-radius: 10px;
$express-banner-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

//Styles of the Main Components and Pages

// Age
$age-logo-max-width: 180px;
$age-card-width: 373px;
$age-logo-max-height: auto;
$age-btn-primary-border-radius: 4px;
$age-modal-shadow: $box-shadow;
$age-title: #030405;
$age-message: #b3b3b3;
$age-message-hover: #ffffff;
$age-link: #6baa33;
$age-background-color: #ffffff;
$age-backdrop: none;
$age-border-card: none;
$age-button-max-width: unset;

// CRM Modal
$crm-header-color: #030405;
$crm-background-color: #ffffff;
$crm-modal-shadow: $box-shadow;

// Not-found 404
$not-found-page: #e0ecd0b8;
$not-found-page-heading-gradient: none;
$not-found-page-title-color: none;

// Promotions
$background-promotion: #6baa33;
$background-promotion-image: none;
$color-text-promotion: #ffffff;
$text-transform-promotions: uppercase;
$efect-promotion: #faf9f8;
$progress-color: #6baa33;

// Header
$header-logo-max-width-mobile: 75px;
$header-logo-fix-height-mobile: 42px;
$header-logo-max-width: 130px;
$header-logo-fix-height: 56px;
$header-logo-background: none;
$header-logo-backdrop-filter: unset;
$header-logo-rounder: 0px;
$background-header: #ffffff;
$background-header-scroll: none;
$header-sticky-filled: none;
$background-header-top: transparent;
$header-background-image: none;
$header-shadow: none;
$header-border-radius: 0px;
$backdrop-filter: unset;
$backdrop-filter-sticky: unset;
$logo-header-spacing-left: 0px;
$logo-headder-min-width: 209px;
$header-action-icon-color: #030405;
$header-divider-top: transparent;
$header-divider: #e9ecf0;
$header-weight-divider: 0px;
$header-border-bottom: transparent;
$header-weight-border-bottom: 1px;
$header-input-placeholder-color: #cccccc;
$header-input-border-color: #cccccc;
$header-input-border-color-activate: #cccccc;
$header-input-background: #fafafa;
$header-input-border-radius: 12px;
$header-input-text-color: #030405;
$header-button-search-color: #66aa33;
$header-button-search-hover-color: #66aa33;
$header-button-icon-color: #ffffff;
$header-button-icon-hover-color: #ffffff;
$header-input-clear-color: 1b2129;
$header-input-separator-color: $header-input-border-color;

// MedRecSwitch
$med-rec-switch-background: #fafafa;
$med-rec-switch-border-radius: 12px;
$med-rec-switch-border-color: #cccccc;
$med-rec-switch-border-color-hover: #cccccc;
$label-btn-mode-rec: #ffffff;
$background-btn-mode-rec: #6baa33;
$background-btn-mode-rec-hover: #6baa33;
$label-btn-mode-med: #b3b3b3;

// Store-Selector
$background-store-selector: #ffffff;
$selector-btn-background: #fafafa;
$selector-btn-border: none;
$selector-btn-text-color: #0d1522;
$selector-btn-address-color: #030405;
$selector-icon-color: #030405;
$selector-heading-color: #030405;
$selector-mode-border-color: #fafafa;
$selector-mode-border-color-hover: #fafafa;
$selector-mode-bg: #fafafa;
$selector-mode-border-radius: $border-radius-md;
$label-btn-mode: #ffffff;
$background-btn-mode: #030405;
$border-color-btn-mode: #030405;
$label-btn-mode-secondary: #b3b3b3;
$background-btn-mode-active: $background-btn-mode;
$border-color-btn-mode-active: $border-color-btn-mode;
$label-btn-mode-active: none;
$selector-store-name-item: $text-color-default;
$selector-text-address-item: #576170;
$selector-store-item-border-bottom: $gray-100;
$selector-store-status-border-left: $gray-50;
$selector-store-mode-title: $gray-500;
$selector-current-location-color: #0d1522;
$selector-shadow: 0px 0px 12px rgba(186, 186, 186, 0.3);

// Store-Validator
$card-validator-title-color: #030405;
$card-validator-background: #ffffff;
$card-validator-alert-border-radius: 8px;
$cart-validator-divider: #f2f4f7;
$cart-validator-text-secundary: #656565;

// GR-Limit
$limit-value: #77879d;
$limit-current-value: #0d1522;
$limit-border-color: #e9ecf0;

// Side Navigation
$background-side-nav: $white;
$side-nav-backdrop-filter: unset;
$background-header-side-nav: $white;
$side-nav-shadow: unset;
$side-nav-logo-max-width: 130px;
$side-nav-logo-max-height: 64px;
$side-nav-border-header: 0.5px solid $gray-100;
$side-nav-button-location-color: $secondary-400;
$side-nav-icon-button-location-color: $secondary-400;
$side-nav-border-main: 0.5px solid $gray-200;
$side-nav-color: $secondary-400;
$side-nav-item-color: $gray-600;
$side-nav-item-icon-color: $label-btn-link;
$side-nav-item-weigth-icon-color: initial;
$side-nav-subitem-color: $secondary-400;
$side-nav-subitem-bg: $gray-50;
$side-nav-active-color: $errors;
$side-nav-active-bg: $gray-50;
$side-nav-scroll-color: $gray-200;

// Menu
$tab-btn: #6baa33;
$nav-text-color: #030405;
$nav-icon-color: $label-btn-link;
$background-nav: $background-header;
$background-nav-sticky: none;
$background-nav-image: $header-background-image;
$backdrop-filter-nav: unset;

$tab-btn-general: $tab-btn;

// Mega Menu
$background-megamenu: #ffffff;
$megamenu-backdrop-filter: unset;
$mega-title-color: #030405;
$mega-text-color: #030405;
$mega-description-color: #576170;
$mega-border-color: #6baa33;
$mega-border-bottom-color: none;
$mega-divider: #e9ecf0;
$mega-link-hover: $tab-btn;
$mega-single-border-radius: $border-radius-md;
$mega-single-border-color: $gray-25;

// Search Header
$search-header-background: #ffffff;
$search-header-backdrop-filter: unset;
$search-title-color: #030405;
$search-border-color: #e9ecf0;
$search-border-width: 0.5px;
$search-see-all-link-color: #030405;
$background-product-card-header: #ffffff;
$search-icon-color: 78879d;
$search-icon-color-focus: 78879d;

// Cart Navigation
$background-shoping-cart: #ffffff;
$shoping-cart-backdrop-filter: unset;
$cart-title-color: #030405;
$cart-btn-close: #576170;
$cart-method-select: #576170;
$cart-market: #030405;
$cart-adderss: #030405;
$cart-divider: #f2f4f7;
$cart-empty-icon: #528829;
$cart-empty-text: #576170;
$cart-footer-text: #576170;
$cart-help-text: #576170;
$cart-footer-price: #030405;
$cart-body-bacground-image: transparent;
$cart-body-border-radius-image: $border-radius-md;
$cart-body-text-color: #030405;
$cart-body-title-color: $gray-600;
$cart-body-brand-color: $body-color-text;
$cart-body-text-color-secondary: #576170;
$cart-bundle-text-color: $primary;
$cart-footer-background: #fafafa;
$cart-footer-divider: $cart-divider;
$cart-badge-on-sale-text-color: $white;
$cart-badge-specials-text-color: $white;
$cart-active-bogo-color: #a7e4bb;
$cart-active-bogo-color-text: #a7e4bb;
$cart-skeleton-color: $placeholder-bg;
$cart-footer-shadow: none;

//Free Delivery Alert
$cart-delivery-message-icon-color: none;
$cart-delivery-message-color: none;
$cart-delivery-message-complete-color: none;
$cart-delivery-progress-background:none;
$cart-delivery-progress-color: none;
$cart-delivery-progress-complete-color: none;

//Empty-bag-gray-scale
$cart-empty-bag-size: 160px;
$cart-empty-bag-base-color: #656565;
$cart-empty-bag-trace-base-color: #989898;
$cart-empty-bag-side-color: #cccccc;
$cart-empty-bag-lateral-stroke: #656565;
$cart-empty-bag-front-bag: #f5f5f5;
$cart-empty-bag-signal: #e6e6e6;
$cart-empty-bag-shadow: #989898;
$cart-empty-bag-counter-badge: #656565;
$cart-empty-bag-text-counter-badge: #fafafa;
$cart-empty-bag-strap: #333333;

/* //Empty-bag
$cart-empty-bag-size: 160px;
$cart-empty-bag-base-color: #B3B3B3;
$cart-empty-bag-trace-base-color: #4D4D4D;
$cart-empty-bag-side-color: #85BB5C;
$cart-empty-bag-lateral-stroke: #528829;
$cart-empty-bag-front-bag: #C2DDAD;
$cart-empty-bag-signal: #FFC941;
$cart-empty-bag-shadow: #989898;
$cart-empty-bag-counter-badge: #E44E41;
$cart-empty-bag-text-counter-badge: #FAFAFA;
$cart-empty-bag-strap: #528829; */

// Hero
$background-hero: #f7f7f7;
$background-hero-image: none;
$border-radius-image-hero: 8px;

// Filter Treez
$background-filters-treez-modal: #ffffff;
$background-filters-treez-desktop: #ffffff;
$filter-title-section: #030405;
$filter-algolia-title: #050606;
$filter-header-close-icon: $secondary-400;
$filter-algolia-title-counter: #576170;
$filter-algolia-bage: #37404e;

//filter toggle
$filter-toggle-icon-color: $primary;
$filter-active-toggle-icon-color: $primary;

//All btn
$filter-btn-border-radius: $border-radius-btn;
$filter-btn-background: #f0f0f0;
$filter-btn-border: #f0f0f0;
$filter-btn-text-color: #030405;
$filter-btn-icon-color: #030405;

$filter-active-btn-background: #f0f0f0;
$filter-active-btn-border: #f0f0f0;
$filter-active-btn-text-color: #030405;
$filter-active-btn-icon-color: #030405;

//filter btn counter
$filter-section-button-counter-background-color: $primary;
$filter-section-button-counter-text-color: #ffffff;
$filter-section-button-counter-border-radius: 20px;

//sort by select
$sort-by-button-background-color: #f0f0f0;
$sort-by-button-border-color: none;
$sort-by-button-text-color: #030405;
$sort-by-button-label-color: #656565;
$sort-by-icon: #030405;

//input
$filter-input-placeholder-color: #cccccc;
$filter-input-border-color: #cccccc;
$filter-input-border-color-activate: #cccccc;
$filter-input-background: #fafafa;
$filter-input-border-radius: 12px;
$filter-input-text-color: #030405;
$filter-button-search-color: $primary;
$filter-button-search-hover-color: $primary;
$filter-button-icon-color: #ffffff;
$filter-button-icon-hover-color: #ffffff;
$filter-input-clear-color: 1b2129;
$filter-input-separator-color: $header-input-border-color;

//search select criterial
$filter-pill-background: #eef9e0;
$filter-pill-text-color: #375321;
$filter-pill-border-color: #eef9e0;
$filter-pill-border-type: solid;

$filter-icon-color: #3d661f;
$filter-control-slider-border-color: $secondary-400;
$filter-control-slider-bg-color: $secondary-300;
$filter-track-slider-border-color: $secondary-400;
$filter-track-slider-bg-color: $secondary-300;
$filter-algolia-divider: #e9ecf0;
$filter-start-active-color: #e5f3d4;
$background-color-collection: #f2f4f7;
$background-placeholder-product: #ebf0f7;

// Controls Slider
$see-all-btn: #ffffff;
$text-see-all-btn: #030405;
$see-all-link: #070c15;
$see-all-counter: #a9b6c9;
$controls-slider: #070c15;
$controls-border: #f7f7f7;
$controls-disabled: #ccd5e3;

// Product
$border-radius-card: 10px;
$background-product-card: #ffffff;
$background-product-card-hover: none;
$product-card-border: none;
$product-card-border-hover: none;
$product-placeholder-color: #ebf0f7;
$product-placeholder-image-color: #f2f4f7;
$product-top-spacing: 0px;
$product-bottom-spacing: 0px;
$product-elevation-hover: 2;
$product-transform-hover: unset;
$product-animation-hover: 0.35s linear 0s 1 normal;
$product-transition: box-shadow 0.25s linear 0s;
$box-shadow-prodct: none;
$box-shadow-prodct-hover: 25px 0 20px -20px rgba(0, 0, 0, 0.08),
  -25px 0 20px -20px rgba(0, 0, 0, 0.08);

$product-info-border-radius: $border-radius-badge;
$product-info-text-color: #0D0D0D;
$product-info-background: #f0efed;
$product-info-broder: #f0efed;

$product-category-border-radius: $border-radius-badge;
$product-category-text-color: #0D0D0D;
$product-category-background: #f0efed;
$product-category-broder: #f0efed;

$product-title: #030405;
$product-stock-text: none;
$product-stock-background: none;
$btn-actions-product: #030405;
$btn-actions-product-background: #ffffff73;
$btn-actions-product-background-hover: #e9ecf0;
$link-inf-product: #8dc457;
$product-brand: #787d87;
$product-divider: #e9ecf0;
$product-divider-default: #e9ecf0;

$product-tch-cbd: #a9b6c9;

$product-price: #030405;
$discount-price: #78879d;
$product-tax-applied-message: #5a6470;

$border-radius-btn-small: none;
$border-radius-link-footer: 10px;
$border-radius-link-footer-active: 10px;
$product-background-footer-active: #c2ddad;
$product-background-footer: #fafafa;
$product-link-footer: $label-btn-link;
$product-link-footer-active: $label-btn-link-hove;

// Product Dark
$product-dark-info-label-color: #b3b3b3;
$btn-actions-product-dark: #030405;
$discount-price-dark: #78879d;
$product-price-dark: #030405;

//bundle popup in card product
$background-bundle-popup: #8dc457;
$text-color-bundle-popup: $white;
$action-close-color: #eef9e0;
$bundle-title-color: #030405;
$bundle-popup-item: #030405;
$bundle-popup-icon: #8dc457;

// Counter
$counter-border: #f0efed;
$counter-divider: #f0efed;
$counter-operation-color: #0d0d0d;
$counter-operation-color-hover: #8dc457;
$counter-text: #0d0d0d;
$counter-border-radius: 8px;
$counter-bacground: #f0efed;
$counter-bacground-count: #f0efed;

// Bundle Specials
$bundle-specials-border-radius: $border-radius-pill;
$bundle-specials-step-border: 1px;
$bundle-specials-border: none;
$bundle-specials-background: #121821;
$bundle-specials-title-color: #ffffff;
$bundle-spacials-descriptions: #b3bcc9;
$bundle-specials-border-default: #5a6573;
$bundle-specials-border-active: #ffffff;
$bundle-special-dropdown-icon: $bundle-specials-border-active;
$bundle-specials-progress-icon-active: $bundle-specials-border-active;
$bundle-special-icon-default: #b3bcc9;
$bundle-special-icon-active: $secondary;
$bundle-special-dropdown-icon: $bundle-specials-border-active;	
$bundle-specials-progress-icon-active: $bundle-specials-border-active;
$special-placeholder-color: #f2f4f7;
$bundle-progress-color: #656565;
$bundle-progress-color-completed: $bundle-progress-color;
$special-bundle-progress-color: none;
$special-bundle-progress-color-completed: none;
$bundle-progress-background: lighten($bundle-progress-color, 30%);
$bundle-badge-text-color: $body-color-text;
$bundle-badge-background: $bundle-specials-border-active;


//Specials Carousel
$specials-card-border-radius: 16px;

//Category and brand carousel
$carousel-categories-placeholder: $special-placeholder-color;
$carousel-brands-placeholder: $special-placeholder-color;

// Rating
$rating-color: $black;

// Rating & Review
$rating-btn-border-radius: 1.5rem;
$rating-review-color: #070c15;
$rating-review-bar-color: #8dc457;
$states-btn-border-color: #0d1522;
$states-btn-text-color: #0d1522;
$states-btn-rating-color: #0d1522;
$states-btn-bg-color: #ffffff;
$states-btn-active-border-color: #54862a;
$states-btn-active-text-color: #375321;
$states-btn-active-bg-color: #eef9e0;
$states-btn-hover-border-color: #54862a;
$states-btn-hover-text-color: #375321;
$states-btn-hover-bg-color: #eef9e0;

// Footer
$footer-adorment-height: 0px;
$footer-adorment-background-image: none;
$footer-logo-max-width: 140px;
$footer-logo-max-height: 64px;
$background-footer: #030405;
$footer-background-image: none;
$border-top: 1px solid $background-footer;
$color-text-footer: $white;
$btn-background-footer: #030405;
$btn-background-footer-hover: #030405;
$text-btn-footer: $white;
$text-btn-footer-hover: $white;
$input-border-color-footer: $white;
$input-border-color-footer-hover: $white;
$input-border-color-footer-activate: $white;
$input-placeholder-color: #b3bcc9;
$input-placeholder-color-activate: $white;
$input-text-color: $white;
$input-background-footer: transparent;
$footer-font-weight-heading: 500;
$footer-font-weight-text: 400;

// Pages
// Signup, Signin forget Pages
$auth-background-color: #ffffff;
$auth-border-radius-card: 12px;
$auth-background-image: none;
$auth-background-card: #f5f5f7;
$auth-border-card: none;
$auth-backdrop: none;
$auth-headding-color: $heading-color;
$auth-text-color: $body-color-text;
$auth-spinner-color: $body-color-text;
$auth-link-default: none;
$auth-logo-bottom-spacing: 24px;
$auth-logo-width: 140px;
$auth-logo-heigth: 65px;

// Account Page
$account-photo-username: #ffffff;
$account-photo-direction: #b6c4c9;
$account-price-discount: #e63e3e;
$account-btn-link: #030405;
$account-btn-link-hover: #6da738;
$account-message-border-color: #bbc1c9;
$account-message-heading-color: #030405;
$account-message-text-color: #2a384e;
$account-tap-color: #030405;

// Product Detail Page
$background-detail: #ffffff;
$detail-divider: #e9ecf0;
$detail-divider-default: #e9ecf0;
$detail-sharing-icon: #8dc457;
$detail-link-category: #8dc457;
$detail-sharing-border-color: #ccd5e3;
$detail-border-rating-review: #e9ecf0;
$detail-counter-border: #ccd5e3;
$detail-counter-operation: #1b2129;
$detail-counter-value-dark: #1b2129;
$detail-link-change-location: #6da738;
$detail-placeholder-color: #ebf0f7;
$detail-background-img: #f7f7f7;
$detail-counter-btn: #6baa33;
$detail-counter-label-btn: $white;
$detail-counter-border-btn: #6baa33;
$detail-counter-btn-hover: #1b2129;
$detail-counter-label-btn-hover: $white;
$detail-counter-border-btn-hover: #1b2129;
$detail-counter-btn-pressed: #0c1014;
$detail-counter-label-btn-pressed: $white;
$detail-counter-border-btn-pressed: #0c1014;
$detail-warning-btn: #2a384e;
$detail-warning-text: #576170;
$detail-stock-border-color: #54862a;
$detail-stock-bg-color: #eef9e0;
$detail-stock-text-color: #375321;
$detail-background-product-info: $white;
$detail-border-radius-product-info: 16px;
$detail-props-badge-bg: transparent;
$detail-props-badge-text-color: #656565;
$detail-props-badge-border-color: #656565;
$detail-icon-props: #070c15;
$detail-label-props: $secondary-400;

//Lab Results
$detail-lab-results-icon-color: #2a384e;
$detail-lab-results-title-text-color: #2a384e;
$detail-lab-results-description-text-color: #787c84;
$detail-lab-results-badge-text-color: #2a384e;
$detail-lab-results-badge-background-color: #cccccc;
$detail-lab-results-active-badge-background-color: $primary;
$detail-lab-results-cannabinoids-label-text-color: #2a384e;
$detail-lab-results-cannabinoids-value-text-color: #626875;

//Active Bogo
$active-bogo: #eef9e0;
$active-bogo-text: #0e4b22;
$active-bogo-border: none;
$active-bogo-content-background: #e0eed6;
$active-bogo-deal-background: #66aa33;
$active-bogo-deal-text-color: #fafafa;
$active-bogo-content-text-color: #528829;
$active-bogo-link-text-color: #656565;

//weight-variant
$detail-weight-variant-btn-border-radius: 12px;
$detail-weight-variant-btn-border-type: solid;
$detail-weight-variant-btn-border: #a6acb4;
$detail-weight-variant-btn-text-color: #656565;
$detail-weight-variant-btn-bg-color: #f0f0f0;
$detail-weight-variant-btn-active-border: #66aa33;
$detail-weight-variant-btn-active-text-color: #656565;
$detail-weight-variant-btn-active-bg-color: #f0f0f0;
$detail-weight-variant-btn-hover-border: #66aa33;
$detail-weight-variant-btn-hover-text-color: #656565;
$detail-weight-variant-btn-hover-bg-color: #f0f0f0;
$detail-weight-variant-btn-info-color: #0d0d0d;
$detail-weight-variant-btn-active-info-color: #66aa33;
$detail-weight-variant-btn-hover-info-color: #66aa33;

//Blogs
$image-border-radius: 16px;

$related-post-background: inherit;
$related-post-title-color: initial;
$related-post-time-color: initial;
$related-post-marging-bottom: 40px;

$blog-filter-bage: $secondary;
$blog-filter-bage-color: #ffffff;

$blog-header-input-placeholder-color: #cccccc;
$blog-header-input-border-color: #cccccc;
$blog-header-input-border-color-activate: #cccccc;
$blog-header-input-background: #fafafa;
$blog-header-input-border-radius: 12px;
$blog-header-input-text-color: #030405;
$blog-header-button-search-color: #66aa33;
$blog-header-button-search-hover-color: #66aa33;
$blog-header-button-icon-color: #ffffff;
$blog-header-button-icon-hover-color: #ffffff;
$blog-header-input-clear-color: 1b2129;
$blog-header-input-separator-color: $header-input-border-color;

//Store Locations
$store-locator-background: #ffffff;
$store-locator-background-modal: #fafafa;

$store-locator-placeholder-map-background: rgba(0, 0, 0, 0.6);

$store-status-border-radius: 4px;
$store-status-closed-border-color: #f8d3cf;
$store-status-closed-color: #f8d3cf;
$store-status-closed-text-color: #85140a;

$store-status-open-border-color: #a7e4bb;
$store-status-open-color: #a7e4bb;
$store-status-open-text-color: #0e4b22;

$store-locator-info-border-radius: 12px;
$store-locator-info-background: #fcfcfc;
$store-locator-info-title: #0d0d0d;
$store-locator-info-text: #656565;

$store-locator-input-placeholder-color: #656565;
$store-locator-input-border-color: #f0f0f0;
$store-locator-input-border-color-activate: #f0f0f0;
$store-locator-input-background: #f0f0f0;
$store-locator-input-border-radius: 12px;
$store-locator-input-text-color: #030405;
$store-locator-button-search-color: #66aa33;
$store-locator-button-search-hover-color: #66aa33;
$store-locator-button-icon-color: #ffffff;
$store-locator-button-icon-hover-color: #ffffff;
$store-locator-input-clear-color: 1b2129;
$store-locator-input-separator-color: $header-input-border-color;

//Order-Detail Page
$order-status-color: $btn-primary;
$order-status-bg-color: transparent;
$order-status-border-color: $btn-primary;
$order-status-active-color: $label-btn-primary;
$order-status-bg-active-color: $btn-primary;
$order-status-border-active-color: $btn-primary;

//Thank You Page
$thanku-banner-text: #4b5e66;
$thanku-banner-title: #0d0d0d;
$thanku-banner-order-number: #eff4f5;
$thanku-order-detail-title: #212121;
$thanku-thead: #6d8992;
$thanku-tbody: #062028;
$thanku-quantity: #4b5e66;
$thanku-status-icon-bg: #cccccc;
$thanku-status-active-icon-bg: #66aa33;
$thanku-status-label-color: #656565;
$thanku-status-active-label-color: #0d0d0d;
$thanku-status-separator-dot-color: #b3b3b3;
$thanku-status-active-separator-dot-color: #66aa33;
$thanku-product-tag-bg: #f0f0f0;
$thanku-product-tag-color: #4b5e66;
$thanku-product-name-color: #0d0d0d;
$thanku-product-qty-bg: #cccccc;
$thanku-product-qty-color: #0d0d0d;
$thanku-order-details-color: #656565;
$thanku-order-details-total-paid-color: #0d0d0d;
$thanku-billing-item-color: #656565;
$thanku-billing-payment-type-bg: #ebf0f7;
$thanku-billing-payment-type-color: #495870;
$thanku-notes-link-color: #528829;
$thanku-picture-path-shadow: #656565;
$thanku-picture-path-shadow-line: #989898;
$thanku-picture-path-popper-bands: #808080;
$thanku-picture-path-popper-body: #e6e6e6;
$thanku-picture-path-popper-hole: #808080;
$thanku-picture-path-popper-hole-shadow: #333333;
$thanku-picture-path-particle-group-1: #ffc941;
$thanku-picture-path-particle-group-2: #528829;
$thanku-picture-path-particle-group-3: #85bb5c;
$thanku-picture-path-particle-group-4: #e44e41;

//KIOSK
$kios-logo-max-heigth: $header-logo-fix-height;
$kios-bakground-default: $primary;
$kiosk-background-header-selector: $background-theme;
$kiosk-border-card-selector: none;
$kiosk-text-color-header: $heading-color;
$kiosk-background-selector: $background-theme;
$kiosk-text-color-selector: $text-color-default;
$kiosk-text-color-selector-hover: $text-color-default;
$kiosk-background-selector-hover: darken($kiosk-background-selector, 5%);
$kiosk-btn-toogle-background: transparent;
$kiosk-btn-toggle-border: 1px solid #989898;
$kiosk-btn-toogle-border-radius: none;
$kiosk-btn-toogle-icon-color: none;

//Kiosk - Product
$kiosk-card-border-radio: 12px;
$kios-card-action-btn-border-radius: 444px;
$kiosk-product-card-border: 1px solid #e6e6e6;
$kiosk-product-card-border-hover: 1px solid #e6e6e6;
$kiosk-card-background-image: none;
$kiosk-card-background: none;
$kiosk-card-background-hover: none;
$kiosk-card-box-shadow: none;
$kiosk-card-box-shadow-hover: none;
$kiosk-card-elevation-hover: auto;
$kiosk-card-transform-hover: none;
$kiosk-card-animation-hover: none;
$kiosk-card-transition: none;
$kiosk-header-card-top-spacing: none;
$kiosk-header-card-bottom-spacing: none;

//Kiosk - Product Detail
$kiosk-prod-detail-header-background: none;
$kiosk-prod-detail-header-shadow: none;
$kiosk-prod-detail-background: none;

//Kios - Timer Modal
$kiosk-idle-modal-background: none;
$kiosk-circular-progress-color: none;
$kiosk-circular-progress-color-background: none;

//Kios - Searh Header
$kiosk-search-header-background: none;

//Kios - Filter
$kiosk-filter-modal-background: none;

//Kiosk - Cart
$kiosk-shoping-cart-background: none;
$kiosk-shoping-cart-title-color: none;
$kiosk-shoping-cart-divider: none;
$kiosk-shoping-cart-footer-text: none;
$kiosk-shoping-cart-footer-background: none;
$kiosk-shoping-cart-footer-price: none;

//Kiosk - Ckeckout
$kiosk-checkout-header-background: none;
$kiosk-checkout-header-shadow: none;

//Kiosk - Empty Cart & Thankyou Page
$kiosk-image-stroke-color: #4D4D4D;
$kiosk-image-main-color: #989898;
$kiosk-image-secondary-color: #656565;

//Regulation Modal	
$regulation-modal-shadow: $box-shadow;
$regulation-modal-background: #ffffff;	
$regulation-modal-header-background: #f5f5f5;	
$regulation-modal-title-color: #0D0D0D;	
$regulation-modal-description-color: #656565;	
$regulation-modal-scroll-control: #B3B3B3;	
$regulation-modal-scroll-background: #F5F5F5;

// Utils Product flower types
$flower-type-default-bg-color: #f7d395;
$flower-type-default-color: #db9317;
$flower-type-sativa-bg-color: #ffdfc2;
$flower-type-sativa-color: #7b522c;
$flower-type-indica-bg-color: #ffbdce;
$flower-type-indica-color: #72273a;
$flower-type-hybrid-bg-color: #f5d4f6;
$flower-type-hybrid-color: #7d3d7d;
$flower-type-vape-bg-color: #43cdf0;
$flower-type-vape-color: #007795;
$flower-type-extract-bg-color: #42c074;
$flower-type-extract-color: #198f48;
$flower-type-cbd-bg-color: #fcd1d1;
$flower-type-cbd-color: #c62221;
$flower-type-si-bg-color: #fce7d3;
$flower-type-si-color: #000000;
$flower-type-is-bg-color: #fdeff2;
$flower-type-is-color: #030303;

//Padding Section
$padding-section: 18px;
$padding-section-mobile: 12px;

$logo-text-padding-bottom: 40px;

//Margin Section
$margin-section: 18px;
$margin-section-mobile: 12px;

//Padding Slice
$padding-slice-mobile-small: 0;
$padding-slice-desktop-small: 0;

$padding-slice-mobile-medium: 12px;
$padding-slice-desktop-medium: 18px;

$padding-slice-mobile-large: 24px;
$padding-slice-desktop-large: 36px;
