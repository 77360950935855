@import '../../../styles/variables';
@import '../../../styles/mixin';

.body {
  order: 0;
  margin: auto 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    text-align: center !important;
    align-items: center !important;
  }

  @include media(lg) {
    padding-left: var(--content-padding-left);
    padding-right: var(--content-padding-right);
    width: 100%;

    &__left {
      order: 1 !important;
    }
  }

  .content__option_item {
    padding-bottom: 40px;
    border-bottom: 1px solid $gray-200;
    margin-bottom: 40px;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.02em;
      margin-bottom: 12px;

      @include media(lg) {
        line-height: 40px;
      }
    }

    .description {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.02em;
      max-width: 950px;

      @include media(lg) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  :last-child {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.figure_container {
  order: 1;
  width: 100%;
  height: fit-content;
  align-self: center;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto auto;
  gap: 12px;

  :first-child {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
  }

  @include media(lg) {
    max-width: 1000px;
    gap: 20px;

    &__left {
      order: 0 !important;
    }
  }

  .figure {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: $gray-50;
    order: 0;

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      color: $gray-300;
    }
  }

  .top_image {
    padding-top: 47.9%;

    @include media(lg) {
      padding-top: 31.2%;
    }
  }

  .bottom_image {
    padding-top: 100%;

    @include media(lg) {
      padding-top: 64.4%;
    }
  }
}
