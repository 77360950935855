@import '../../styles/variables';
@import '../../styles/mixin';

.testimonials__container {
  position: relative;

  .testimonials {
    padding: 0 15px;
    margin: 0 auto;

    @include media(sm) {
      padding: 0 20px;
    }

    div[class^='Slider_slider_header__'] {
      margin: 0 !important;
      padding: 0 !important;
      width: 100%;

      h1 {
        font-size: $text-3xl;
        line-height: 40px;
        font-weight: 700;
      }
    }

    span[class~='swiper-pagination-bullet'] {
      background-color: $swiper-bullet;
    }

    span[class~='swiper-pagination-bullet-active'] {
      background-color: $swiper-bullet-active !important;
    }

    &__logos_container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 32px;
      column-gap: 80px;
      row-gap: 32px;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 16px;

      @include media(sm) {
        margin-top: 8px;
      }

      .logo_image {
        position: relative;
        width: 152px;
        height: 41px;
      }
    }

    blockquote {
      display: flex;
      flex-direction: column;
      gap: 32px;
      text-align: center;
      font-weight: 400;
      color: $gray-500;

      p {
        margin: 0;
      }

      .user {
        display: flex;
        flex-direction: column;

        > p {
          font-weight: 700;
          font-size: $text-xl;
          line-height: 20px;
          color: $secondary-400;
          order: 0;
        }

        span {
          font-size: $text-base !important;
          line-height: 30px;
          color: $gray-300;
          font-weight: 400;
          order: 1;
        }
      }
    }

    &__default {
      margin: 0 auto;
      max-width: 766px;

      @include media(sm) {
        padding-top: 72px;
      }

      .card {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
        padding-bottom: 32px;

        @include media(sm) {
          row-gap: 14px;
          padding-bottom: 0;
        }

        .quote__icon {
          order: 0;
        }

        blockquote {
          order: 1;
          > p {
            order: 1;
            font-size: $text-2xl;
            line-height: 32px;
            font-weight: 700;
            margin-bottom: 0;

            @include media(sm) {
              font-size: $text-3xl;
              line-height: 40px;
            }
          }

          .user {
            margin-bottom: 0;
            order: 2;
          }

          @include media(sm) {
            height: 100%;
            text-align: left;
          }
        }
      }

      i {
        font-size: 20px;
        color: $secondary-400;

        @include media(sm) {
          position: absolute;
          left: 10px;
          top: -40px;
        }
      }

      div[class~='swiper-pagination'] {
        bottom: 0;
        text-align: center;

        @include media(sm) {
          padding-right: 20px;
          text-align: right;
        }
      }
    }

    &__rating {
      max-width: 1022px;
      // padding: 78px 0;
      padding-bottom: 38px;

      // @include media(sm) {
      //   padding: 64px 0;
      // }
      @include media(sm) {
        padding-bottom: 0;
      }

      blockquote {
        p:first-of-type {
          order: 1;
          font-size: $text-xl;
          line-height: 26px;
          margin-bottom: 0;
        }

        div[class='rating'] {
          order: 0;
        }

        .user {
          margin-bottom: 0;
          order: 2;

          @include media(sm) {
            margin-top: 12px;
          }
        }

        i {
          font-size: $text-3xl;
          color: $gray-100;
          margin-right: 8px;
          &::before {
            content: '\e82f';
          }
        }

        i[class~='icon_star'] {
          color: $rating-color;
        }

        @include media(sm) {
          padding-left: 104px;
          padding-right: 104px;
        }
      }

      div[class~='swiper-pagination'] {
        display: block;
        bottom: 0;

        @include media(sm) {
          display: none;
        }
      }

      button[class~='slick-prev'],
      button[class~='slick-next'] {
        position: absolute !important;
        top: 40%;
        transform: translateY(-40%);
        width: 48px;
        height: 48px;
        border: 0;
        border-radius: 50%;
        background: $gray-50;
        z-index: 1;
      }

      button[class~='slick-prev'] {
        left: 10px;

        &::before {
          content: '\e804';
        }

        &::after {
          display: none;
        }
      }

      button[class~='slick-next'] {
        right: 10px;

        &::before {
          content: '\e805';
        }
      }
    }

    &__rating_text_center {
      div[class~='swiper-pagination'] {
        display: block;
        text-align: left;
        padding-left: 15px;
        padding-right: 15px;

        @include media(sm) {
          display: none;
        }
      }

      .card {
        margin-bottom: 56px;
        padding-top: 24px;

        @include media(sm) {
          margin-bottom: 80px;
        }
      }

      blockquote {
        text-align: left;
        line-height: 22px;
        gap: 24px;
        font-size: $text-base;

        > p {
          order: 1;
          margin: 8px 0 0;
          font-size: $text-base;
        }

        div[class='rating'] {
          order: 2;
          color: $gray-300;
        }

        .user {
          margin-bottom: 0;
          padding-bottom: 24px;
          border-bottom: 1px solid $gray-200;
          order: 0;
        }

        i {
          font-size: $text-xl;
          color: $gray-100;
          margin-right: 8px;
          &::before {
            content: '\e82f';
          }
        }

        i[class~='icon_star'] {
          color: $rating-color;
        }
      }

      figure {
        i {
          font-size: 40px;
        }
      }
    }

    &__quote_with_heading {
      div[class^='Slider_slider_header__'] {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        align-items: center;
        text-align: center;

        h2 {
          font-size: $text-3xl;
          line-height: 40px;
          font-weight: 700;
        }

        p {
          font-size: $text-base;
          line-height: 20px;
          max-width: 604px;
          margin-bottom: 0;
        }
      }

      .card {
        margin: 36px 0 40px;
        padding: 24px 24px 27px;
        border: 1px solid $secondary-400;
        border-radius: $border-radius-md;
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include media(sm) {
          flex-direction: row;
        }
      }

      figure {
        position: relative;
        display: block;
        width: 100%;
        max-width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 50%;
        background-color: $gray-100;
        order: 0;
        flex-shrink: 0;

        i {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 80px;
          color: $gray-200;
        }
      }

      .quote__icon {
        font-size: 20px;
        color: $secondary-400;
        position: absolute;
        right: 56px;
        top: 78px;

        @include media(sm) {
          left: 156px;
        }
      }

      blockquote {
        text-align: left;
        justify-content: space-between;
        line-height: 22px;
        gap: 24px;
        font-size: $text-base;
        order: 1;

        .description_container {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .card_description {
            order: 0;
            letter-spacing: -0.2px;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            line-clamp: 6;
            overflow: hidden;
            text-overflow: ellipsis;

            @include media(sm) {
              margin-top: 62px;
            }
          }

          .show_more {
            -webkit-line-clamp: unset;
            line-clamp: unset;
            display: block;
          }

          .show_more_button {
            align-self: flex-end;
            font-size: $text-sm;
            font-weight: bold;
          }
        }

        .user {
          gap: 18px;
          order: 1;

          @include media(sm) {
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .info_socials {
          order: 2;
          font-size: 20px;
          color: $secondary-400;

          :first-child {
            margin-left: 0;
          }

          a {
            margin-left: 9px;
          }
        }
      }
    }

    &__future_logo {
      @extend .testimonials__quote_with_heading;
      max-width: 1232px;

      .card {
        position: relative;
      }

      figure {
        position: absolute;
        width: 100%;
        max-width: 50px;
        height: 50px;
        order: 2;
        left: 24px;
        bottom: 24px;

        i {
          font-size: 50px;
          z-index: 1;
        }
      }

      .quote__icon {
        order: 0;
        position: absolute;
        left: 24px;
        top: 24px;
      }

      blockquote {
        position: relative;
        justify-content: space-between;
        height: 100%;
        margin-top: 66px;
        order: 1;

        @include media(sm) {
          height: auto;
        }

        > p {
          order: 0;
          @include media(sm) {
            margin-top: 0;
          }
        }

        .user {
          order: 1;
          gap: 2px;
          flex-direction: column;
          padding-left: 70px;

          p {
            font-size: $text-base;
          }

          span {
            font-size: $text-xs !important;
            line-height: 20px;
          }
        }
      }
    }

    .location__reviews {
      margin-top: 88px;
    }

    &_location__rating_top_title {
      display: flex;
      line-height: 56px;
      align-items: center;
      position: relative;

      div[class~='swiper-pagination'] {
        display: block;
        bottom: -22px;
        text-align: left;
        padding-left: 15px;
        padding-right: 15px;

        @include media(sm) {
          display: none;
        }
      }

      [class~='slick-prev'] {
        position: absolute;
        top: 52%;
        transform: translate(0, -52%);
        display: none !important;
        border: 1px solid $gray-200;
        width: 52.99px;
        border-right: 0;

        @include media(sm) {
          display: block !important;
        }
      }

      button[class~='slick-prev'],
      button[class~='slick-next'] {
        position: absolute !important;
        top: 40%;
        transform: translateY(-40%);
        width: 48px;
        height: 48px;
        border: 0;
        border-radius: 50%;
        background: $gray-50;
        z-index: 1;
      }

      button[class~='slick-prev'] {
        left: 10px;

        &::before {
          content: '\e804';
        }

        &::after {
          display: none;
        }
      }

      button[class~='slick-next'] {
        right: 10px;

        &::before {
          content: '\e805';
        }
      }
    }

    .location__reviews-title {
      display: flex;
      line-height: 56px;
      align-items: center;
      position: relative;

      [class~='slick-prev'] {
        position: absolute;
        top: 52%;
        transform: translate(0, -52%);
        display: none !important;
        border: 1px solid $gray-200;
        width: 52.99px;
        border-right: 0;

        @include media(sm) {
          display: block !important;
        }
      }

      [class~='slick-next'] {
        width: 15.58px;
        height: 15.18px;
        top: 40%;
        transform: translate(0, -41%);
        display: none !important;
        width: 52.99px;
        border-left: 0;

        @include media(sm) {
          display: block !important;
        }
      }
    }

    .location__reviews-flex_container {
      width: 100%;
      height: auto;
      display: flex;
      gap: 24px;
    }

    .reviews_title {
      margin-bottom: 20px;
    }
    .reviews_title p {
      font-size: $text-xl;
      font-weight: 700;
    }

    .reviews-card {
      flex: 0 0 auto;
      padding-top: 24px;
      padding-bottom: 24px;
      width: 88vw;
      height: auto;
      display: flex;
      flex-direction: column;

      @include media(md) {
        width: 360px;
        padding: 24px;
      }

      @include media(lg) {
        width: 475px;
      }

      @include media(xl) {
        width: 385px;
      }

      @include media(2xl) {
        width: 475px;
      }
    }

    .card_title {
      margin-bottom: 18px;
      font-weight: 700;
      font-size: $text-lg;
      line-height: 24px;
      color: $black;
    }

    .card_stars {
      display: flex;
      gap: 10.88px;
      padding-bottom: 19px;
      color: $secondary-400;

      [class~='icon'] {
        font-size: $text-xl;
        color: $gray-200;
        width: 17.11px;
        height: 16.36px;
      }

      i[class~='icon_star'] {
        color: $rating-color;
      }

      i[class~='icon_star-half-alt'] {
        color: $rating-color;
      }
    }

    .card_desc {
      font-weight: 400;
      font-size: $text-base;
      line-height: 24px;
      color: $gray-500;
      text-align: left;
    }

    .person_info {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 15px;
    }

    .person_desc {
      display: flex;
      flex-direction: column;
    }

    .rating_person_desc {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .rating_card_stars {
      @extend .card_stars;
      padding-bottom: 0px;
    }
    .rating_person_info {
      @extend .person_info;
      margin-bottom: 19px;
      padding-bottom: 24px;
      border-bottom: 1px solid $gray-200;
    }

    .rating_person_img {
      position: relative;
      display: block;
      width: 100%;
      max-width: 51px;
      height: 51px;
      overflow: hidden;
      border-radius: 50%;
      background-color: $primary-300;
      order: 0;
      flex-shrink: 0;

      i {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 51px;
        color: $primary-100;
      }
    }

    .person_name {
      font-weight: 700;
      font-size: $text-base;
      line-height: 24px;
      color: $secondary-400;
    }

    .person_position {
      font-size: $text-base !important;
      line-height: 30px;
      color: $gray-300;
      font-weight: 400;
    }

    .person_from {
      line-height: 24px;
    }

    .person_img {
      position: relative;
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      background-color: $primary-200;
    }

    .person_img > img {
      position: relative;
      width: 64px;
      height: 64px;
      transform: translateY(6px);
    }
    .person_img > svg {
      width: 40px;
      height: 40px;
    }
  }
}
