@import '../../../styles/variables';
@import '../../../styles/mixin';

.content__item_list {
  order: 0;
  margin: auto 0;
  display: flex;
  flex-direction: column;

  @include media(lg) {
    padding-left: var(--content-padding-left);
    padding-right: var(--content-padding-right);

    &__left {
      order: 1 !important;
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    text-align: center !important;
    align-items: center !important;
  }

  .content__option_item {
    padding-bottom: 40px;
    border-bottom: 1px solid $gray-200;
    margin-bottom: 40px;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.02em;
      margin-bottom: 12px;

      @include media(xl) {
        line-height: 40px;
      }
    }

    .description {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.02em;

      @include media(lg) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  :last-child {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    color: $gray-400;
    order: 3;

    @include media(xl) {
      font-size: $text-xl;
      line-height: 26px;
    }
  }
}

.figure_container {
  display: flex;
  flex-direction: column;
  order: 1;
  align-items: center;
  align-self: center;
  text-align: center;

  @include media(lg) {
    &__left {
      order: 0 !important;
    }
  }

  .description {
    order: 6;
    margin-top: 16px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;

    @include media(lg) {
      font-size: 16px;
      margin-top: 36px;
    }
  }

  .figure {
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: $gray-50;
    order: 0;
    aspect-ratio: 1.35 / 1;

    @include media(lg) {
      margin-bottom: 36px;
      order: 1;
      aspect-ratio: 1.52 / 1;
    }

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      color: $gray-300;
    }
  }
}
