@import '../../styles/variables';
@import '../../styles/mixin';

.blogs {
  display: flex;
  align-items: center;
  
  &__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > h2:first-of-type {
      max-width: 464px;
      color: $gray-500;
      font-weight: 700;
      font-size: $text-2xl;
      line-height: 32px;
      margin-bottom: 41px;

      @include media(sm) {
        margin-bottom: 48px;
      }
    }
  }
}

.blogs__articles {
  flex-grow: 1;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 32px;
  column-gap: 24px;
  width: 100%;

  &_default {
    @media screen and (min-width: 520px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(lg) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &_column_heading {
    @include media(sm) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media(xl) {
      padding: 0 156px;
    }
  }

  &_column_hybrid {
    gap: 0;

    div {
      margin-top: 14px;
    }

    .article {
      h2 {
        color: $gray-500;
      }
    }

    article:nth-child(2) {
      padding-bottom: 33px;
      padding-top: 8px;
      border-bottom: 1px solid $gray-100;
    }

    article:last-of-type {
      padding-top: 32px;
      border-bottom: 0;
      padding-bottom: 0;

      h2 {
        color: $gray-500;
      }
    }

    article:first-child {
      border-bottom: 1px solid $gray-100;
      padding-bottom: 56px;

      h2:nth-of-type(1) {
        font-size: 30px;
        line-height: 38px;
        font-weight: 700;
        color: $gray-500;
      }

      p:nth-of-type(1) {
        font-size: $text-xl;
        line-height: 26px;
      }

      div:nth-of-type(1) {
        > p {
          font-size: $text-base;
          line-height: 20px;
        }
      }
    }

    @include media(md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: 1fr 1fr;
      gap: 0;
      position: relative;

      article:first-child {
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        margin-right: 110px;
        padding: 30px 0;
        border-bottom: 0;
        display: flex;
        align-items: center;
        height: 100%;

        h2:nth-of-type(1) {
          font-size: 40px;
          line-height: 48px;
        }
      }
    }
  }
}

.article {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 6px;
  position: relative;

  blockquote {
    order: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 400;
    line-height: 20px;

    &.blockquote_absolute {
      bottom: 0;
      padding: 20px;
      position: absolute;
      z-index: 2;

      &__top {
        top: 0;
      }
    }

    h2 {
      font-size: $text-xl;
      line-height: 26px;
      font-weight: 700;
      color: $gray-500;
    }

    p {
      font-size: $text-base;
      color: $gray-dark;
    }

    i {
      color: $primary-400;
      margin-right: 10px;
    }

    span {
      margin-right: 26px;
    }
  }

  .article__figure {
    order: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.85%;
    border-radius: $border-radius-md;
    background-color: $gray-50;
    margin-bottom: 18px;

    &_fullWidthVariation {
      border-radius: $padding-section-mobile;

      &--absolute {
        height: 100%;
        margin-bottom: 0;
      }
    }

    img {
      height: auto;
    }

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $gray-300;
      font-size: 24px;
    }
  }

  .overlap {
    background: linear-gradient(
      180deg,
      rgba(7, 12, 21, 0) 0%,
      rgba(7, 12, 21, 0.9) 100%
    );
    border-bottom-left-radius: $padding-section-mobile;
    border-bottom-right-radius: $padding-section-mobile;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    &--inverse {
      background: linear-gradient(
        180deg,
        rgba(7, 12, 21, 0.9) 0%,
        rgba(7, 12, 21, 0) 100%
      );
      border-radius: 0;
      border-top-left-radius: $padding-section-mobile;
      border-top-right-radius: $padding-section-mobile;
    }
  }

  time {
    order: 1;
    font-size: $text-xs;
    font-weight: 500;
    color: $gray-300;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    &.time {
      order: 0;
    }
  }

  &_fullWidth {
    height: 100%;
  }

  &_heading {
    .article__figure {
      padding-top: 100%;

      img {
        height: auto;
      }
    }

    blockquote {
      gap: 12px;

      h3 {
        font-size: $text-base;
        line-height: 26px;
        font-weight: 700;
        color: $gray-500;
      }

      p {
        font-size: $text-sm;
        line-height: 18px;
        color: $gray-dark;
      }
    }
  }

  &_hibryd {
    .user {
      display: flex;
      gap: 10px;

      .article__figure {
        width: 40px;
        height: 40px;
        padding-top: 0;
        border-radius: 50%;
        margin-bottom: 0;
        order: 0;

        img {
          height: 40px;
        }
      }

      i {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        color: $gray-200;
      }

      p {
        order: 1;
        color: $secondary-400;
        font-weight: 700;
        line-height: 20px;

        span {
          font-size: $text-xs;
          font-weight: 400;
          color: $gray-400;
        }
      }
    }
  }
}
