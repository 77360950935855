@import './variables';

@mixin media($size) {
  @media screen and (min-width: #{map-get($breakpoints, $size)}) {
    @content;
  }
}

@mixin container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;

  @include media(sm) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @each $size, $i in $breakpoints {
    @include media($size) {
      max-width: $i;
    }
  }
}

@function get-value($default-value, $value) {
  @return if($value == none, $default-value, $value);
}

@function add-hash-if-needed($color) {
  @if type-of($color) == 'number' or str-index(#{$color}, '#') == null {
    @return unquote('##{$color}');
  }
  @return $color;
}

