@import '../../../styles/variables';
@import '../../../styles/mixin';

.body {
  display: flex;
  width: 100%;

  @include media(lg) {
    padding-left: var(--content-padding-left);
    padding-right: var(--content-padding-right);
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    gap: 24px;

    @include media(lg) {
      gap: 32px;
    }

    &_with_background {
      height: 100%;
      justify-content: center;
      align-self: stretch;
      padding: 10%;
    }

    .body__text_heading {
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: -0.02em;

      @include media(lg) {
        font-size: 16px;
      }
    }

    .body__heading_icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      overflow: hidden;

      img {
        flex-grow: 1;
        height: auto;
        width: auto;
      }
    }

    .body__title_container {
      display: flex;
      gap: 24px;

      .body__title {
        order: var(--title-order);
        font-size: $text-lg + $text-xs;
        line-height: 40px;
        font-weight: 600;
        letter-spacing: -0.02px;

        @include media(lg) {
          font-size: 32px;
          line-height: 40px;
        }
        @include media(2xl) {
          font-size: 40px;
          line-height: 48px;
        }
        @include media(3xl) {
          font-size: 48px;
          line-height: 56px;
        }
      }

      .body__title_icon {
        order: var(--icon-order);
        display: contents;

        & > img {
          width: 62px !important;
          height: 62px !important;
        }

        @include media(lg) {
          & > img {
            width: 80px !important;
            height: 80px !important;
          }
        }
      }
    }

    .body__title_icon_centered {
      margin-bottom: 24px;
      margin-top: -24px;

      & > span {
        width: 62px !important;
        height: 62px !important;
      }

      @include media(lg) {
        & > span {
          width: 80px !important;
          height: 80px !important;
        }
      }
    }

    .cta {
      align-self: var(--cta-align);
      min-width: 216px;
      max-width: 293px;
      width: 100%;

      @include media(xl) {
        max-width: 366px;
      }
    }

    .description_section {
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: -0.02em;

      @include media(lg) {
        font-size: 16px;
      }
    }
  }
}

.image_carousel_swiper_container {
  align-self: center;
  justify-self: center;
  padding: 0;

  span[class~='swiper-pagination-bullet'] {
    background-color: $swiper-bullet; /* Change the color of the bullet */
  }

  span[class~='swiper-pagination-bullet-active'] {
    background-color: $swiper-bullet-active !important; /* Change the color of the bullet */
  }

  .image_carousel_swiper {
    background-color: $background-theme;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .image {
    height: auto;
    position: relative;
    align-self: center;
    overflow: hidden;
    width: auto;

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      color: $gray-300;
    }

    &__default {
      aspect-ratio: 1/1;
      background: $gray-50;
    }
  }
}
