@import '../../../styles/variables';
@import '../../../styles/mixin';

.body {
  order: 1;
  width: 100%;
  display: grid;
  height: fit-content;
  margin: auto;
  gap: 16px;

  @include media(lg) {
    order: 0;

    &__left {
      order: 1 !important;
    }
  }

  @include media(xl) {
    gap: 32px;
  }

  @media screen and (min-width: 420px) {
    grid-template-columns: 1fr 1fr;
  }

  @include media(lg) {
    order: 0;
    padding-left: var(--content-padding-left);
    padding-right: var(--content-padding-right);
    width: 100%;
  }

  .body__item_grid_feature {
    border-left: 4px solid;
    height: 100%;
    padding: 10px 0px 10px 14px;

    @include media(xl) {
      padding: 24px 0px 24px 24px;
    }

    &_border_right {
      padding: 10px;

      @include media(lg) {
        border-right: 4px solid;
        border-left: 0;
      }

      @include media(xl) {
        padding: 24px;
      }
    }

    .title {
      font-size: $text-base;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.02em;
      margin-bottom: 10px;

      @include media(lg) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .description {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.02em;

      @include media(lg) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.body__figure {
  position: relative;
  height: auto;
  width: 100%;
  overflow: hidden;
  background-color: $gray-50;
  aspect-ratio: 1.35 / 1;
  order: 0;

  @include media(lg) {
    position: relative;
    width: 100%;
    padding-top: 83%;
    overflow: hidden;
    height: 0;
    order: 1;
    align-self: center;

    &__left {
      order: 0 !important;
    }
  }

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
    color: $gray-300;
  }
}
