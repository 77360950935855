@import '../../styles/variables';
@import '../../styles/mixin';

.zipcodes_section {
  width: 100%;

  .zipcodes_container {
    @include media(md) {
      display: grid;
      align-items: center;
      grid-template-columns: minmax(0, 1fr) auto;
    }

    .zipcodes-page__filter {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      margin-top: 20px;

      @include media(sm) {
        max-width: 391px;
      }

      @include media(md) {
        margin-top: 25px;
      }

      .icon {
        font-size: 26px;
        color: $secondary-400;
        position: absolute;
        left: 11px;
      }

      .input {
        border-radius: 10px 0 0 10px;
        border: 2px solid $gray-200;
        padding-left: 24px;

        &:focus-within {
          border: 2px solid $primary;
        }

        [type='search'] {
          padding-left: 40px;
          margin-left: 0px;
        }
      }

      .button {
        border-radius: 0 10px 10px 0;
        height: 56px;
        box-shadow: none;
        font-size: 14.7px;
        letter-spacing: 0.8px;

        span {
          font-size: $text-base;
          line-height: 24px;
        }
      }
    }

    .zipcodes__description {
      margin-bottom: 30px;
      font-size: $text-xl;
    }

    .zipcodes-page__list {
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 3;
      display: flex;
      flex-wrap: wrap;
      color: $gray-500;
      row-gap: 20px;
      column-gap: 10px;

      li {
        padding: 5px 14px;
        display: flex;
        align-items: center;
      }
    }

    .collection__load-more {
      margin-top: 45px;
      font-weight: 500;
      font-size: $text-lg;

      @include media(md) {
        grid-column: 1 / -1;
      }
    }
  }
}
