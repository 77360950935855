@import '../../../styles/variables';
@import '../../../styles/mixin';

.items_section {
  order: 4;
  width: 100%;
  margin-top: 32px;

  .item_slider {
    [class*='slider_header'] {
      display: none;
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 190px;

      @include media(lg) {
        max-width: 236px;
      }

      .image {
        margin-bottom: 20px;

        & > img {
          width: 62px;
          height: 62px;

          @include media(lg) {
            width: 80px;
            height: 80px;
          }
        }

        &__custom_width {
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .item_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-size: $text-lg;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: -0.02em;
          margin-bottom: 8px;
        }

        .description {
          font-size: $text-sm;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
        }
      }
    }
  }
}
