@use "sass:math";
@import '../../styles/variables';
@import '../../styles/mixin';

:global(.dark-theme) {
  .tabreview {
    .details__tabs_list {
      border-bottom: 0.5px solid $detail-divider;
    }
  }
}

.tabreview {
  font-size: $text-base;
  font-weight: 400;
  color: $gray-600;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  // display: table-row;
  margin-top: 40px;
  margin-bottom: 24px;

  @include media(lg) {
    margin-bottom: 0;
  }

  .details__tabs_list {
    border-bottom: 0.5px solid $detail-divider-default;
    position: relative;
    z-index: 1;

    button {
      color: $gray-600;
      border: 0px;
      padding: 16px 16px;
      font-weight: 500;
      transition: all 0.3s ease;
      white-space: nowrap;
      line-height: 1;
      outline: 0;

      span {
        color: $gray-300;
      }

      &.details__tab_active {
        color: $tab-btn-general;
        background: $background-theme;
      }

      &.details__tab_active::before {
        content: '';
        width: 64px;
        justify-content: center;
        border-bottom: 4px solid $tab-btn-general;
        position: absolute;
        bottom: 0;
        left: 42px;
      }

      &:hover {
        color: $tab-btn-general;
      }
    }
  }

  .details__tab_description {
    display: none;
    border-top: 1px solid $gray-300;
    padding: 16px 13px;
    overflow-y: auto;
    max-height: 450px;
    z-index: 1;

    @include media(sm) {
      padding: 16px 15px;
    }

    &.details__tab_reviews_active {
      display: block;
    }
  }

  .review__empty {
    display: flex;
    flex-direction: column;
    padding: 36px 0px 0px;
    height: 100px;

    strong {
      color: $tab-btn-general;
      font-weight: 500;
    }

    p {
      padding-top: 5px;
      font-weight: 400;
      color: $gray-500;
    }
  }

  .review__filter_empty {
    @extend .review__empty;
    height: 60px;
    padding: 0;
  }
}

.filters {
  display: flex;
  margin-top: 36px;
  margin-right: -16px;
  $size: 28px;

  .filters__container_main {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 36px;
  }

  .filters__container_h3 {
    font-size: $text-base !important;
    font-weight: 500;
    display: none;

    @include media(sm) {
      display: flex;
      align-items: center;
    }
  }

  .filters__container {
    display: flex;
    flex-grow: 1;
    overflow: auto hidden;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::before {
      background: linear-gradient(to right, $white 50%, rgba($white, 0));
      right: auto;
      left: 0;
    }

    @include media(lg) {
      &::after,
      &::before {
        content: '';
        position: -webkit-sticky;
        pointer-events: none;
        position: sticky;
        top: 0;
        right: 0;
        display: block;
        background: linear-gradient(to left, $white 50%, rgba($white, 0));
        flex: 0 0 16px;
      }
    }
  }

  .filters__container_sorted-by {
    display: none;

    @include media(lg) {
      display: block;
      min-width: 90px;
    }
  }

  .filters__title_panel_list {
    font-size: $text-base;
    margin-bottom: 16px;
    color: $gray-500;
  }

  .filters__item {
    flex-shrink: 0;

    + .filters__item {
      margin-left: 2px;

      @include media(md) {
        margin-left: 8px;
      }
    }
  }

  .filters__item-button {
    border: 1px solid $gray-200;
    border-radius: $border-radius-btn;
    color: $secondary-400;
    font-size: $text-base;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 4px 12px 4px 16px;
    background: transparent;
    flex-wrap: wrap;
    align-content: center;
    height: 32px;

    &.filters__item-button_active {
      border-color: $secondary-400;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      border-color: $hover;
      color: $hover;
    }

    span {
      display: flex;
      justify-content: space-between;
    }

    &.filters__item-button-rage {
      width: 190px;
    }
  }

  .filters__item-panel {
    $form-width: 200px;
    $space: 16px;

    border-radius: $border-radius;
    // box-shadow: $box-shadow;
    max-width: $form-width;
    padding: $space;
    background: $white;
    z-index: 20;
    width: calc(100% - #{$space * 2});
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.08);

    @include media(sm) {
      width: $form-width;
    }
  }

  .filters__list-container-label {
    margin-top: 10px;
    color: $gray-300;
    cursor: pointer;

    &:hover {
      color: $secondary-400;
    }

    &:active,
    &:-ms-keyboard-active {
      color: $secondary-400;
    }
  }

  .filters__list-container-label-active {
    color: $secondary-400;
  }

  .filters__list {
    .button {
      color: $gray-300;

      &:first-child {
        padding-left: 0;
      }

      + .button {
        margin-left: 8px;
      }
    }

    .filters__list-container {
      overflow-y: auto;
      max-height: 225px;
      margin: 10px -16px 0 -1px;
      padding-left: 1px;
      padding-right: 16px;

      .input + .input {
        margin-top: 10px;
      }
    }
  }

  .filters__item-button-clear {
    font-size: $text-base;
    background: $tab-btn-general;
    color: $white;
    border-radius: $border-radius-pill;
    padding: 4px 16px;
    margin-left: 8px;
    text-align: center;

    &:hover {
      background: $tab-btn-general;
      color: $white;
    }
  }

  .review__btn-load-more {
    margin-right: 28px;

    @include media(sm) {
      margin-right: 17px;
    }
  }

  .review__btn-clear-filter {
    min-width: 100px;
    margin-left: 8px;
  }
}

.placeholder__product_reviews {
  margin-bottom: 48px;

  .placeholder__review_actions_filters {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 36px;

    .placeholder__text {
      height: 20px;
      width: 80px;
    }

    .placeholder__criteria_filter {
      height: 30px;
      width: 100px;
    }
  }

  .placeholder__review_container {
    display: flex;
    flex-direction: column;

    .placeholder__review_item {
      display: flex;
      align-items: center;
      height: 100px;
      width: 100%;
      border-bottom: 1px solid $gray-50;

      .placeholder__review_figure_costumer {
        display: flex;
        column-gap: 16px;
        width: 33%;

        .placeholder__review_figure {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background-color: $placeholder-bg-300;
        }

        .placeholder__review_staus {
          height: 16px;
          width: 30%;
          margin-top: 16px;
        }
      }

      .placeholder__review_description {
        width: 47%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        .placeholder__text {
          width: 100%;
          height: 10px;
        }
      }

      .placeholder__text:nth-of-type(1) {
        width: 35%;
      }

      .placeholder__text:nth-of-type(2) {
        width: 85%;
      }

      .placeholder__text:nth-of-type(3) {
        width: 91%;
      }

      .placeholder__text:nth-of-type(4) {
        width: 54%;
      }

      .placeholder__text_date {
        width: 20%;
        display: flex;
        justify-content: end;
        margin-right: 16px;

        .placeholder__text {
          height: 16px;
          width: 90px;
          background-color: $placeholder-bg-100;
        }
      }
    }
  }
}
