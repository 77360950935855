@import '../../../styles/variables';
@import '../../../styles/mixin';

.items_section {
  order: 4;
  padding-top: 24px;
  margin-top: 24px;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid $gray-100;

  .item_slider {
    [class*='slider_header'] {
      display: none;
    }

    .item {
      display: flex;
      width: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .image {
        position: relative;
        height: 34px;

        & > img {
          height: 34px;
        }

        &__custom_width {
          height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &_align_right {
      div[class~='swiper-wrapper'] {
        justify-content: flex-end !important;
      }
    }
  }
}
