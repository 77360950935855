@import '../../styles/variables';
@import '../../styles/mixin';

.instagram {
  
  &__container {
    
    &_full_width {
      width: 100 !important;
      padding: 0;
      margin: 0;
      max-width: none !important;
    }

    .instagram__header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      padding: 24px 0;

      @include media(sm){
        gap: 24px;
        padding: 32px 0;
      }

      &_non_space{
        padding: 0 0 24px 0;

        @include media(sm){
          padding: 0 0 32px 0;
        }
      }

      &_username {
        color: $primary-300;
        font-size: 1.75rem;
      }

      button {
        max-width: 139px;
        margin-left: 16px;

        > div {
          column-gap: 8px;
          span {
           line-height: 1px;
          }
        }
      }
    }

    .post__image_link {
      position: relative;
      overflow: hidden;
      transition: transform 0.3s ease;

      .post__content {
        position: absolute;
        background: rgba(0,0,0, 0.8);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        padding: 8.3%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 16px;
        opacity: 0;
        transition: opacity 0.3s ease;

        @include media(md){
          row-gap: 24px;
        }

        .post__stats {
          display: flex;
          column-gap: 20px;

          @include media(lg){
            column-gap: 40px;
          }

          &_item {
            display: flex;
            align-items: center;
            column-gap: 8px;
            font-weight: 500;
            color: $white;

            span {
              line-height: 1px;
            }
            
            svg {
              * {
                stroke: $white;
              }
            }
          }
        }

        .post__text {
          color: $white;
          text-align: center;
          display: -webkit-box;
          line-clamp: 3;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 100px;

          @include media(lg){
            line-clamp: 4;
            -webkit-line-clamp: 4;
            max-height: 100px;
          }

          p + p {
            margin-top: 8px;
          }
        }
      }

      &:hover, &:focus, &:focus-within {
        .post__content {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
   }
}